var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"associated"},[_c('div',{staticClass:"switch_tab"},[_c('div',{staticClass:"label_management"},[_c('div',{staticClass:"input-with"},[_c('el-input',{staticClass:"input-with-select",attrs:{"placeholder":"请输入项目名称"},model:{value:(_vm.queryInfo.projectName),callback:function ($$v) {_vm.$set(_vm.queryInfo, "projectName", $$v)},expression:"queryInfo.projectName"}},[_c('el-button',{staticClass:"seach",attrs:{"slot":"append"},on:{"click":function($event){return _vm.getInspectDemand()}},slot:"append"},[_vm._v("搜索")])],1)],1),_c('el-table',{staticClass:"in_table",staticStyle:{"width":"100%"},attrs:{"data":_vm.listData,"stripe":"","header-cell-style":{ background: '#F8F9FB', color: '#595959' }}},[_c('el-table-column',{attrs:{"label":"编号","align":"center","type":"index"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s((_vm.queryInfo.pageNum - 1) * _vm.queryInfo.pageSize + scope.$index + 1))])]}}])}),_c('el-table-column',{attrs:{"label":"项目名称","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.projectName))])]}}])}),_c('el-table-column',{attrs:{"label":"项目时间","prop":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.startTime)+"至"+_vm._s(row.endTime))])]}}])}),_c('el-table-column',{attrs:{"label":"项目目的","prop":"projectPurpose","align":"center"}}),_c('el-table-column',{attrs:{"label":"项目地点","prop":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.city)+"-"+_vm._s(row.region))])]}}])}),_c('el-table-column',{attrs:{"label":"考察人数","prop":"inspectNumber","align":"center"}}),_c('el-table-column',{attrs:{"label":"考察领域","prop":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((row.areasInvestigation),function(o,index){return _c('span',{key:index},[_c('span',{staticStyle:{"color":"#4e93fb","padding":"0px 5px"}},[_vm._v("#"+_vm._s(o.areas[1]))])])})}}])}),_c('el-table-column',{attrs:{"label":"操作","align":"center","class-name":"small-padding fixed-width"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{staticStyle:{"color":"#146AFF"},attrs:{"type":"text"},on:{"click":function($event){return _vm.torecord(scope.row)}}},[_vm._v("详情 ")])]}}])})],1),_c('div',{staticClass:"new_page"},[_c('el-pagination',{attrs:{"current-page":_vm.queryInfo.pageNum,"page-sizes":[10, 30, 50],"page-size":_vm.queryInfo.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }