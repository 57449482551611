import request from "@/utils/request"
//查询商会信息列表
export function commercelist(query) {
  return request({
    url: "/commerce/list",
    method: "get",
    params: query
  })
}

//查询商会信息列表（不带分页）
export function commerceListTwo(query) {
  return request({
    url: "/commerce/listTwo",
    method: "get",
    params: query
  })
}
//新增商会
export function addcommerce(data) {
  return request({
    url: "/commerce",
    method: "post",
    data: data
  })
}
//编辑商会
export function editcommerce(data) {
  return request({
    url: "/commerce",
    method: "put",
    data: data
  })
}
//获取商会详细
export function getcommercet(id) {
  return request({
    url: "/commerce/" + id,
    method: "get",

  })
}
//删除商会
export function delcommercet(ids) {
  return request({
    url: "/commerce/" + ids,
    method: "delete",

  })
}
//获取企业关联异地考察需求信息列表
export function getInspectDemand(query) {
  return request({
    url: "/commerce/getInspectDemand",
    method: "get",
    params: query
  })
}
//查询商会企业信息列表
export function getchamberlist(query) {
  return request({
    url: "/chamberCommerceCompany/list",
    method: "get",
    params: query
  })
}
//查询商会用户列表
export function getchamberuser(query) {
  return request({
    url: "/chamberCommerceUser/list",
    method: "get",
    params: query
  })
}
//新增关联用户
export function adduser(data) {
  return request({
    url: "/chamberCommerceUser",
    method: "post",
    data: data
  })
}
//编辑
export function edituser(data) {
  return request({
    url: "/chamberCommerceUser",
    method: "put",
    data: data
  })
}
//关联用户详情
export function getCommerceUser(id) {
  return request({
    url: "/chamberCommerceUser/" + id,
    method: "get",

  })
}
//删除商会
export function delCommerceUser(ids) {
  return request({
    url: "/chamberCommerceUser/" + ids,
    method: "delete",

  })
}

//查询商会关联单位
export function getguidance(query) {
  return request({
    url: "/guidance/list",
    method: "get",
    params: query
  })
}
//新增指导单位
export function addguidance(data) {
  return request({
    url: "/guidance",
    method: "post",
    data: data
  })
}
//编辑
export function editguidance(data) {
  return request({
    url: "/guidance",
    method: "put",
    data: data
  })
}
//单位详情
export function getguidanceinfo(id) {
  return request({
    url: "/guidance/" + id,
    method: "get",

  })
}
//删除单位
export function delguidance(ids) {
  return request({
    url: "/guidance/" + ids,
    method: "delete",

  })
}
//新增企业信息
export function addCommerceCompan(data) {
  return request({
    url: "/chamberCommerceCompany",
    method: "post",
    data: data
  })
}
//编辑
export function editCommerceCompan(data) {
  return request({
    url: "/chamberCommerceCompany",
    method: "put",
    data: data
  })
}

//删除企业
export function delcompany(ids) {
  return request({
    url: "/chamberCommerceCompany/" + ids,
    method: "delete",

  })
}

//修改商会邀约函
export function editInvitation(data) {
  return request({
    url: "/commerce/editInvitation",
    method: "put",
    data: data
  })
}


//新增商会邀约函
export function addInvitation(data) {
  return request({
    url: "/commerce/addInvitation",
    method: "post",
    data: data
  })
}


//获取邀请函
export function getInvitation(id) {
  return request({
    url: "/commerce/getInvitation?id=" + id,
    method: "get"
  })
}
